<template>
  <div class="myteam">
    <van-nav-bar
      title="我的邀请"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="teamlist">
      <div class="teamlist_main" v-if="list.length > 0 ? true : false">
        <van-list
          v-model="teamLoading"
          :finished="teamFinished"
          finished-text="没有更多了"
          @load="teamOnLoad"
        >
          <van-cell center title-class="van-ellipsis" v-for="(item, index) in list" :key="index">
            <template #icon>
              <div class="list_img">
                <van-image
                  round
                  width="60"
                  height="60"
                  :src="item.avatar"
                  lazy-load
                >
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
                <van-tag
                  round
                  color="#efddb6"
                  text-color="#914602"
                  style="line-height: 20px; padding: 0 8px;"
                  @click="copy(item.agent_code)"
                >{{item.agent_code}}</van-tag>
              </div>
            </template>
            <template #title>
              <div style="display: flex; margin-right: 10px;">
                <span class="van-ellipsis" style="margin-right: 5px;">{{item.nickname}}</span>
                <van-tag
                  round
                  color="#efddb6"
                  text-color="#914602"
                  style="line-height: 20px; padding: 0 8px;"
                >{{item.levelname}}</van-tag>
              </div>
            </template>
            <template #label>
              <div class="van-ellipsis">{{item.addtimeStr}}</div>
            </template>
            <template #right-icon>
              <div class="right_main">
                <div class="right_btn" v-if="item.level == 0 && (userinfo.level1_white == 1 || userinfo.check_level == 0)">
                  <van-button
                    size="small"
                    type="info"
                    round
                    @click="shengTeji(index)"
                  >升特级</van-button>
                </div>
                <div class="right_icon">粉丝数：{{item.num}}</div>
              </div>
            </template>
          </van-cell>
        </van-list>
      </div>
      <div class="teamlist_main" v-if="nodata">
        <van-empty description="空空如也" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MyTeam",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      teamFinished: false, // 成员是否加载中
      teamLoading: false, // 成员是否加载完成
      currSize: 20, // 每次加载多少条
      currOffset: 0, // 已经加载多少条数据
      list: [], // 数据里列表内容
      myTeamActive: 0, // 当前所在位置
      teamList: [], // 所有数据
      shareid: 0,
      merchid: 0,
      nodata: false,
      userinfo: {}, // 用户信息
    }
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;

    this.$parent.getmerchname(this.merchid, "我的邀请");
    this.init();
  },
  methods: {
    init() {
      console.log("sss");
      this.teamOnLoad();
      this.getinfo();
    },
    // 返回上一页
    onClickLeft(){
      this.$router.go(-1);
    },
    // 获取用户信息
    getinfo() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/getinfor",
          _that.$qs.stringify({
            merchid: _that.merchid
          })
        )
        .then(response => {
          console.log(response.data);
          if (response.data) {
            _that.userinfo = response.data;
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    // 加载直接成员数据
    teamOnLoad(){
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/commission/myteam",
          _that.$qs.stringify({
            status: _that.myTeamActive,
            offset: _that.currOffset,
            size: _that.currSize
          })
        )
        .then(response => {
          _that.teamLoading = false; // 直/间接成员已加载好
          _that.nodata = false;
          if (response.data.code == 100000) {
            if (response.data.data.length > 0) {
              response.data.data.forEach((item, index) => {
                _that.list.push(item);
              }) 
            }
            if (response.data.data.length >= _that.currSize) {
              _that.currOffset += _that.currSize;
            }else {
              _that.teamFinished = true; // 直/间接成员加载完成
              _that.currOffset += response.data.data.length;
            }
          }else {
            _that.teamFinished = true; // 直/间接成员加载完成
            _that.nodata = true;
          }
        })
        .catch(error => {
          console.log(error);
          _that.teamFinished = true; // 直/间接成员加载完成
        })
    },
    // 升免考核特级
    shengTeji(index) {
      let _that = null;
      _that = this;
      let name = "";
      name = _that.list[index].nickname ? _that.list[index].nickname : "未设置昵称";

      _that.$dialog.confirm({
        title: '提升等级',
        message: "确定要提升【" + name + "】为特级？",
      })
        .then(() => {
          // on confirm
          console.log("确认按钮");
          _that.$toast({
            type: "loading",
            message: "升级中...",
            overlay: true,
            forbidClick: true,
            duration: 0
          })
          _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
          _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
          _that.$axios
            .post(
              _that.$store.state.domain + "web/user/up_teji",
              _that.$qs.stringify({
                memberid: _that.list[index].id
              })
            )
            .then(response => {
              _that.$toast.clear();
              if (response.data.code == 100000) {
                _that.$toast(response.data.msg ? response.data.msg : '升级成功')
                _that.list[index].level = 6;
                _that.list[index].levelname = "特级会员";
              }else {
                _that.$toast(response.data.msg ? response.data.msg : '升级失败')
              }
            })
            .catch(error => {
              console.log(error);
              _that.$toast.clear();
            })
        })
        .catch(() => {
          // on cancel
          console.log("取消按钮");
        });
    },
    copy(val) {
      let _that = null;
      _that = this;
      _that.$copyText(val).then(
        function(e) {
          _that.$toast("复制成功");
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      );
    },
  }
}
</script>
<style lang="less">
.myteam{
  width: 100%;
  min-height: 100%;
  background-color: #ededed;
  position: relative;
  z-index: 1;
  overflow: hidden;
  .van-nav-bar{
    .van-icon{
      color: #323233;
    }
  }
  .teamlist {
    width: 100%;
    text-align: left;
    .van-cell{
      padding: 10px;
      .van-cell__title{
        flex: 1;
        margin-left: 10px;
        font-size: 16px;
        .van-cell__label{
          font-size: 14px;
        }
      }
      .right_btn .van-button {
        height: 24px;
        line-height: 24px;
        margin-bottom: 5px;
        padding: 0 10px;
      }
      .right_icon{
        color: #939393;
      }
      .list_img {
        position: relative;
        .van-tag {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          text-align: center;
          font-size: 10px;
        }
      }
    }
  }
  
}
</style>
